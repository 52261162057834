html, body {
  font-family: poppins, sans-serif;
  margin: 0;
  height: 100%;

  button {
    padding: .5em;
  }

  #root {
    & > div {
      min-height: 100vh;

      .main {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;
        padding: 1em 0;
      }

      form {
        input[type=text] {
          outline: none;
          border: 1px solid #bfbfbf;
          padding: 1em;
          border-radius: 4px;
          width: 400px;
          font-family: poppins, sans-serif;
          box-sizing: border-box;
        }
      }

      .content {
        padding: 2em;

        .quill {
          position: relative;
          z-index: 2;
          border-radius: 4px;
          border: 1px solid #7D7D7D;

          .ql-toolbar {
            background: #FFF;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          .ql-container {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            .ql-editor {
              li {
                &::before {
                  background: transparent;
                }
              }
            }
          }
        }

        .note {
          z-index: 2;
          position: relative;
          text-align: right;
          font-size: 11px;
        }

        .toolbar {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          max-width: 400px;
          padding: 0.2em 0;
          background: #FAFAFA;
          border-radius: 4px;
          margin: 1em auto;
          border: 1px solid #7D7D7D;
        }

        .separator {
          background: #d5d5d5;
          border-radius: 4px;
          width: 2px;
          padding: 0;
          margin: 0;
          display: block;
        }

        .btn {
          min-width: 2.5em;
          outline: none;
          border: none;
          cursor: pointer;
          margin: 0.5em;
          border-radius: 4px;
          line-height: 0;
        }

        .content-inner {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          margin: 0 auto;
          word-break: break-word;

          .columns {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            gap: 10px;
            overflow-y: scroll;
            overscroll-behavior: auto;
            scroll-snap-type: x mandatory;
            scroll-snap-align: center;
            max-height: calc(100vh - 101.66px);
            overflow: scroll;
          }

          .message {
            width: 100%;
            text-align: start;
          }

          ul:not(& ul ul):not(.quill ul) {
            padding: 1em 0;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            min-width: 305px;

            li:not(& ul li):not(& ol li):not(.quill ul li):not(.quill ol li):not(.quill li ul li):not(.quill li ol li) {
              list-style: none;
              padding: 2em .75em 1em .75em;
              border-radius: 4px;
              position: relative;
              align-items: center;
              display: flex;
              gap: .25em;
              font-size: .9em;
              scroll-snap-align: start;

              .delete-btn {
                min-width: 2em;
                background: transparent;
                margin: 0;
                position: absolute;
                top: 5px;
                right: 0;
                outline: none;
                border: none;
                cursor: pointer;
                border-radius: 4px;
                line-height: 0;
              }

              .label {
                background: #7d7c7c;
                color: #FFF;
                border-radius: 12px;
                padding: 0.5em 0.75em;
                font-size: .65em;
                position: absolute;
                top: 5px;
                right: 30px;
              }

              &::before {
                content: '';
                position: absolute;
                bottom: 0px;
                top: 0px;
                left: 0px;
                width: 3px;
              }

              &::after {
                content: '';
                position: absolute;
                left: -6px;
                top: 2px;
                height: 15px;
                width: 15px;
              }
            }
          }

          ul ul, ol ol, ul ol {
            padding-left: 1.25em;
          }

          ul:nth-of-type(1) {
            list-style-type: disc;
          }
        }
      }
    }

    .settings {
      position: absolute;
      width: 300px;
      height: 100%;
      right: -300px;
      top: 0;
      z-index: 3;
      backdrop-filter: blur(7px);

      &.slideIn {
        right: 0;
      }

      &.slideOut {
        right: -300px;
        display: none;
      }

      .input-group {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        padding: 1em;

        input[type=text] {
          outline: none;
          border: 1px solid #bfbfbf;
          padding: .5em;
          border-radius: 4px;
          font-family: poppins, sans-serif;
          box-sizing: border-box;
        }
      }

      .select-background {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
        gap: 1em;
        align-items: center;
        justify-content: center;
      }

      .close-btn {
        min-width: 2em;
        background: transparent;
        margin: 0;
        position: absolute;
        top: 5px;
        right: 0;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        line-height: 0;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    right: -300px;
  }
  100% {
    right: 0;
  }
}

@keyframes slideOut {
  0% {
    right: 0;
  }
  100% {
    right: -300px;
  }
}

body::-webkit-scrollbar {
  display: none;
}